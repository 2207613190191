import React from 'react';

// MUI Includes
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// MUI Icon Includes
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';


const Header = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{
      marginBottom: '10px'
    }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        
        <Menu
          id="help-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'help-menu',
          }}
        >
          <MenuItem onClick={handleClose}>How-To</MenuItem>
          <MenuItem onClick={handleClose}>Help Me!</MenuItem>
          <MenuItem onClick={handleClose}>Songs</MenuItem>
          <MenuItem disabled={true} onClick={handleClose}>Advanced</MenuItem>
        </Menu>


        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          BAT Tree Controller
        </Typography>
        <IconButton 
          size='large'
          color='inherit' 
          aria-label="change settings"
          onClick={props.openSettings}
          sx={{p:0}}
        >
          <SettingsIcon />
        </IconButton>
      </Toolbar>
      
    </AppBar>
  );
}

export default Header;
