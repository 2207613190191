import React from 'react';
import { useState } from 'react';
import Icon from '@mui/material/Icon';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const EffectsList = (props) => {
  const [listOpen, setListOpen] = useState(true);

  return (
    props.effects.length > 0 &&
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton onClick={() => setListOpen(!listOpen)}>
        <ListItemIcon>
          <Icon>{props.icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={props.name} />
        {listOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={listOpen} timeout="auto" unmountOnExit>
        {props.effects.map((effect) => {
          const audioSatisfied = (props.needsAudio === effect.hasAudio);
          return (
            audioSatisfied ? 
            <List key={effect.name} component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} onClick={() => {props.setEffect(effect.index)}}>
                <ListItemText primary={effect.name} />
                {effect.active && <CheckCircleIcon color='inherit' />}
              </ListItemButton>
            </List>
            :
            <React.Fragment key={effect.name}></React.Fragment>
          )
        })}
      </Collapse>
    </List>
  )
};

export default EffectsList;