import { useEffect } from 'react';
import React from 'react';
import { HashRouter, Link, Routes, Route } from 'react-router-dom';

import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';
import { amber, deepOrange, grey } from '@mui/material/colors';
import Controller from '../components/controller/Controller';
import Dashboard from '../components/dashboard/Dashboard';

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      ...amber,
      ...(mode === 'dark' && {
        main: amber[300],
      }),
    },
    ...(mode === 'dark' && {
      background: {
        default: deepOrange[900],
        paper: deepOrange[900],
      },
    }),
    text: {
      ...(mode === 'light'
        ? {
            primary: grey[900],
            secondary: grey[800],
          }
        : {
            primary: '#fff',
            secondary: grey[500],
          }),
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
});


const AppRouter = () => (
  <ThemeProvider theme={darkTheme}>
    <HashRouter basename='/'>
      <div>
        <Routes>
          <Route path='/' element={<Controller />} />
          {/*<Route path='/controller' element={<Controller />} />*/}
          {/*<Route path='/edit' element={<EditExpensePage />} />*/}
          {/*<Route path='/help' element={<HelpPage />} />*/}
          {/*<Route path='*' element={<NotFoundPage />} />*/}
        </Routes>
      </div>
    </HashRouter>
  </ThemeProvider>
);

export default AppRouter;