import { useState, useRef, useEffect } from 'react';
import React from 'react';

// MUI Includes
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

// MUI Icon Includes
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

// Local Includes
import EffectsList from './EffectsList';
import Header from './Header';
import SettingsDialog from './SettingsDialog';


const Controller = () => {

  const [connected, setConnected] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [effects, setEffects] = useState([]);
  const [brightness, setBrightness] = useState(0);
  const [volume, setVolume] = useState(0);
  const [redGain, setRedGain] = useState(0);
  const [greenGain, setGreenGain] = useState(0);
  const [blueGain, setBlueGain] = useState(0);
  const [bleError, setBleError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  let effectsRef = useRef(effects);
  let batDevice = useRef(undefined);
  let batCharacteristic = useRef(undefined);
  let BatState = useRef('connect');
  let numEffects = useRef(0);
  let currentEffect = useRef(0);
  let currentEffectName = useRef('');
  let initialized = useRef(false);
  
  useEffect(() => {
    document.title = 'BAT Controller';
  }, []);

  // Update effects ref when effects change so the
  // handleMessageReceived callback has the most recent info
  useEffect(() => {
    // Any time effects gets updated, update the
    // ref for the handleMessageReceived callback
    effectsRef.current = effects;
  }, [effects])

  
  const onClickConnect = () => {
    // Reset BLE Error if there was one
    setBleError(false);

    // Try to find the BAT Tree device
    let filters = [];
    filters.push({namePrefix: "BAT Tree"});
    let optionalServices = ['0000ffe0-0000-1000-8000-00805f9b34fb'];
    console.log(optionalServices);
    let options = {};
    options.filters = filters;
    options.optionalServices = optionalServices;
    console.log('Requesting Bluetooth Device...');

    // Request the device with the options
    navigator.bluetooth.requestDevice(options)

    // If success, connect to GATT server
    .then(device => {
      console.log('Connecting to GATT Server...');
      console.log(device);
      batDevice.current = device;
      batDevice.current.addEventListener('gattserverdisconnected', onDisconnected);
      return batDevice.current.gatt.connect();
    })

    // Grab the primary service for the BLE controller
    .then(server => {
      console.log('Getting Primary Service...');
      return server.getPrimaryService(0xFFE0);
    })

    // Connect to the characteristic
    .then(service => {
      console.log('Getting Custom Characteristic...');
      return service.getCharacteristic(0xFFE1);
    })

    // Register callback when connected to characteristic
    .then(characteristic => {
      console.log('We got all the way to the end!');
      batCharacteristic.current = characteristic;
      batCharacteristic.current.addEventListener('characteristicvaluechanged', handleMessageReceived);
      batCharacteristic.current.startNotifications();

      //  Update the state so connect goes away
      setConnected(true);
    })

    // Once successfully connected, start queries
    .then(() => {
      BatState.current = 'queryNumEff';
      batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode('NumEff\n'));
    })

    // When something goes wrong in the promise chain
    .catch(error => {
      console.log(error);
      // Show the error string to the user so they can try
      // to do something about it.
      setErrorMessage(error.message);
      setBleError(true);
    });

  }

  const onDisconnected = () => {
    console.log("I detect that the server disconnected! Now what?");
    setConnected(false);
    setSettingsOpen(false);
    setEffects([]);
    setBrightness(0);
    setVolume(0);
    setRedGain(0);
    setGreenGain(0);
    setBlueGain(0);
    setBleError(true);
    setErrorMessage('BAT Tree Disconnected. Please reconnect.');

    batDevice.current = undefined;
    batCharacteristic.current = undefined;
    BatState.current = 'connect';
    numEffects.current = 0;
    currentEffect.current = 0;
    currentEffectName.current = '';
    initialized.current = false;
  }

  
  const handleMessageReceived = (event) => {
    let respStr = String.fromCharCode.apply(null, new Uint8Array(event.target.value.buffer));
    if (respStr === "EOE") {
      console.log("EOE received");

      // I don't want an EOE to interrupt initialization
      if (initialized.current === true) {
        // When we get an EOE, we request from the tree
        // which effect is currently playing, which will
        // update the list checkbox next to active effect
        batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode('GetCur\n'));
        BatState.current = 'queryCur';
      }

      return;
    }

    else if (BatState.current === 'queryNumEff') {
      console.log(`There are ${respStr} effects`);
      numEffects.current = parseInt(respStr);
      console.log('I should request them!');

      // Send the query for the first effect to start the fun
      currentEffect.current = 0;
      setTimeout(() => { batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode('GetEff 0\n')); }, 50);

      BatState.current = 'gatherEffects';
    }

    else if (BatState.current === 'gatherEffects') {
      currentEffectName.current = `${currentEffectName.current}${respStr}`;

      // If we receive the * character, we know we got the end of the message
      if (respStr.indexOf('*') > -1) {
        console.log(`Full effect name: ${currentEffectName.current}`);
        // Strip off the last character (*) and split on :
        const effectDetails = currentEffectName.current.slice(0, -1).split(':');
        console.log(effectDetails);

        setEffects((prevEff) => [...prevEff,
        {
          name: effectDetails[0],
          active: false,
          index: currentEffect.current,
          hasAudio: effectDetails[1] === '1'
        }]);
        

        // Increment effect number and reset the full effect name
        currentEffect.current++;
        currentEffectName.current = '';

        // If there are more effects
        if (numEffects.current > currentEffect.current) {
          const queryString = `GetEff ${currentEffect.current}\n`;
          setTimeout(() => { batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode(queryString)); }, 50);
        }
        // If we're done grabbing effects
        else {
          console.log('All effects have been received!');
          
          if (initialized.current) {
            BatState.current = 'readyToRock';
          }
          else {
            BatState.current = 'queryBright';
            batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode('GetBright\n'));
          }

        }
      }
      // If the message exceeds 20 characters, it must be sent as multiple messages
      else {
        console.log('I only received a partial message');
      }
    }

    else if (BatState.current === 'queryBright') {
      setBrightness(parseInt(respStr));

      if (!initialized.current) {
        BatState.current = 'queryVol';
        batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode('GetVol\n'));
      }
      else {
        BatState.current = 'readyToRock';
      }
    }

    else if (BatState.current === 'queryVol') {
      setVolume(parseInt(respStr));
      
      if (!initialized.current) {
        BatState.current = 'queryRed';
        batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode('GetRed\n'));
      }
      else {
        BatState.current = 'readyToRock';
      }
    }

    else if (BatState.current === 'queryRed') {
      setRedGain(parseFloat(respStr));
      if (!initialized.current) {
        BatState.current = 'queryGreen';
        batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode('GetGreen\n'));
      }
      else {
        BatState.current = 'readyToRock';
      }
    }

    else if (BatState.current === 'queryGreen') {
      setGreenGain(parseFloat(respStr));
      if (!initialized.current) {
        BatState.current = 'queryBlue';
        batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode('GetBlue\n'));
      }
      else {
        BatState.current = 'readyToRock';
      }
    }

    else if (BatState.current === 'queryBlue') {
      setBlueGain(parseFloat(respStr));
      if (!initialized.current) {
        BatState.current = 'queryCur';
        batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode('GetCur\n'));
      }
      else {
        BatState.current = 'readyToRock';
      }
    }

    else if (BatState.current === 'queryCur') {
      console.log(`Current: ${respStr}`)
      console.log(`First Char: ${respStr[0]} Second Char: ${respStr[1]}`)

      // Clear out all checkmarks
      let effs = [...effectsRef.current];

      effs.forEach((effect) => { effect.active = false; })

      const effectIndex = parseInt(respStr[1]);

      if (respStr[0] === 'E') {
        effs[effectIndex].active = true;
      }
      else {
        console.log('Uhh... Oops?');
      }
      
      setEffects(effs);

      initialized.current = true;
      BatState.current = 'readyToRock';
    }

    else {
      console.log(`Response: ${respStr}`);
    }

  }

  const setEffect = (effNum) => {
    console.log(`I got called with ${effNum}`);
    batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode(`SetEff ${effNum}\n`));
    BatState.current = 'queryCur';
  }

  const handleAdjBright = (increase) => {
    const sendCmd = (increase === true ? 'IncBright\n' : 'DecBright\n');
    batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode(sendCmd));
    BatState.current = 'queryBright';
  }

  const handleAdjVol = (increase) => {
    const sendCmd = (increase === true ? 'IncVol\n' : 'DecVol\n');
    batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode(sendCmd));
    BatState.current = 'queryVol';
  }

  const handleAdjRed = (increase) => {
    const sendCmd = (increase === true ? 'IncRed\n' : 'DecRed\n');
    batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode(sendCmd));
    BatState.current = 'queryRed';
  }

  const handleAdjGreen = (increase) => {
    const sendCmd = (increase === true ? 'IncGreen\n' : 'DecGreen\n');
    batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode(sendCmd));
    BatState.current = 'queryGreen';
  }

  const handleAdjBlue = (increase) => {
    const sendCmd = (increase === true ? 'IncBlue\n' : 'DecBlue\n');
    batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode(sendCmd));
    BatState.current = 'queryBlue';
  }

  const handleStopRequest = () => {
    BatState.current = 'queryCur';
    batCharacteristic.current.writeValueWithoutResponse(new TextEncoder().encode(`Stop\n`));
  }


  return (
    <Box sx={{flexGrow: 1, pb: '20px'}}>
      <CssBaseline />
      <Header openSettings={() => setSettingsOpen(true)} /> 
      <Container maxWidth='sm' sx={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent:'vertical'}}>
        {/* Display the Connect button only when there 
            is not a valid connection to a BAT Tree */}
        {!connected && 
          <Box 
            sx={{
              display: 'flex',
              width: '100%',
              height: '90%',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'background.default',
              color: 'text.primary',
              borderRadius: 1,
              p: 3,
            }}>
            <Button onClick={onClickConnect}>Connect</Button>  
          </Box>
        }
        {/* Display all Patterns */}
        {connected &&
          <EffectsList 
            name='Patterns' 
            effects={effects}
            needsAudio={false} 
            setEffect={setEffect} 
            icon={<EmojiObjectsIcon />} 
          />
        }
        {/* Display all Animations */}
        {connected &&
          <EffectsList 
            name='Animations'
            effects={effects}
            needsAudio={true}
            setEffect={setEffect} 
            icon={<MusicNoteIcon />} 
          />
        }
        {connected && 
          <Button onClick={handleStopRequest}>Stop Playing</Button>}
      </Container>

      {/* Adjust brightness and volume with a modal window */}
      <SettingsDialog 
        open={settingsOpen} 
        onClose={() => setSettingsOpen(false)} 
        connected={connected}
        brightPct={brightness} 
        volPct={volume} 
        redGain={redGain}
        greenGain={greenGain}
        blueGain={blueGain}
        adjBrightness={handleAdjBright}
        adjVolume={handleAdjVol}
        adjRed={handleAdjRed}
        adjGreen={handleAdjGreen}
        adjBlue={handleAdjBlue}
      />
      
      {/* Display an Alert if there's a problem connecting to BLE */}
      {bleError &&
        <Alert 
          variant='outlined' 
          severity='error'
          sx={{
            m: '20px'
          }}
        >
          {errorMessage}
        </Alert>
      }
    </Box>
  );
}

export default Controller;