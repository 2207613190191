import React from 'react';

// MUI Imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';

// MUI Icon Imports
import Brightness6Icon from '@mui/icons-material/Brightness6';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const MustConnect = () => {
  return (
    <Box 
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: 'background.default',
      color: 'text.primary',
      borderRadius: 1,
      pt: 2,
      pb: 2,
      pl: 5,
      pr: 5
    }}
  >
    <Typography variant='h6'>Please connect to adjust settings.</Typography>
  </Box>
  )
}

const SettingsDialog = (props) => {
  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Settings</DialogTitle>
      {!props.connected && <MustConnect />}
      {props.connected && 
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.default',
          color: 'text.primary',
          borderRadius: 1,
          pt: 2,
          pb: 2,
          pl: 5,
          pr: 5
        }}
      >
        <Brightness6Icon color='inherit' />
        <div>
          <Button 
            onClick={() => props.adjBrightness(false)}
            sx={{
              minWidth: '30px',
              p:0,
              m:0
            }}
          >
            -
          </Button>
          <Input 
            sx={{
              width: '50px', 
              p: 0, 
              m: 0
            }} 
            inputProps={{ style: { textAlign: 'center' }}}
            readOnly={true} 
            value={props.brightPct} 
          />
          <Button 
            onClick={() => props.adjBrightness(true)}
            sx={{
              minWidth: '30px',
              p:0,
              m:0
            }}
          >
            +
          </Button>
        </div>
        <VolumeUpIcon color='inherit' sx={{mt:'25px'}} />
        <div>
          <Button 
            onClick={() => props.adjVolume(false)}
            sx={{
              minWidth: '30px',
              p:0,
              m:0
            }}
          >
            -
          </Button>
          <Input 
            sx={{
              width:'50px', 
              p: 0, 
              m: 0
            }} 
            inputProps={{ style: { textAlign: 'center' }}}
            readOnly={true} 
            value={props.volPct} 
          />
          <Button 
            onClick={() => props.adjVolume(true)}
            sx={{
              minWidth: '30px',
              p:0,
              m:0
            }}
          >
            +
          </Button>
        </div>
        
        <Button 
          onClick={props.onClose}
          sx={{
            mt: '25px'
          }}
        >
          Okie Dokie
        </Button>
      </Box>
        }
    </Dialog>
  );
}


export default SettingsDialog;